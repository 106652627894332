import { convert } from '@js-joda/core';
import {
  REGISTRATION_STEPS,
  RegistrationFormKey,
  UserGender,
  UserStatus,
  LpQueryStr,
  DateSlot,
  ratingPoint,
  USER_CERTIFICATE_TYPE,
  USER_CERTIFICATE_STATUS,
  datingDays,
  DEFAULT_MAX_LENGTH_TEXT_INPUT,
  MAX_LENGTH_TEXT_INPUT_FULL_WIDTH,
  CostPlan,
  ResponseStatus,
  SUBSCRIPTION_PAUSE_ERROR_TYPE
} from '../constants/constant';
import isString from 'lodash/isString';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import queryString from 'query-string';
import { ReactComponent as Menu } from '@assets/newSystem/squareItem/svg/menu.svg';
import { ReactComponent as ArrowLeftChat } from '@assets/common/arrow_left_chat.svg';
import React from 'react';
import classNames from 'classnames';
import styleSvg from '../styles/index.module.css';
import i18n from 'i18next';
import 'moment/locale/ja';
import { WEEK_ORDER } from '@containers/MatchProfile/type';
import { isNil } from 'lodash';
import TagManager from 'react-gtm-module';
import { store } from '@redux/store';
import { requestSendExceptionNotice } from '@redux/actions';
import { ReactComponent as Age } from '@assets/SettingDatingItems/age.svg';
import { ReactComponent as Eye } from '@assets/SettingDatingItems/eye.svg';
import { ReactComponent as BodyType } from '@assets/SettingDatingItems/body_type.svg';
import { ReactComponent as Clock } from '@assets/SettingDatingItems/clock.svg';
import { ReactComponent as Map } from '@assets/SettingDatingItems/map.svg';

export const formatDatetimeToString = (
  datetime,
  datetimeFormat,
  stringFormat
) => {
  return moment(datetime, datetimeFormat)
    .locale('ja')
    .format(stringFormat);
};

export const showTimeRemaining = secs => {
  const date = secs / 86400;
  let newDate = '';
  if (date <= 1) {
    newDate = secs;
  } else {
    newDate = `${Math.round(date)}日`;
  }
  return newDate;
};

export const generateSelectedIndexes = (data, value) => {
  return data
    .map(item => {
      return parseInt(item.value);
    })
    .indexOf(parseInt(value));
};

export const generateYearData = () => {
  const data = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i <= currentYear + 10; i++) {
    data.push({ value: i.toString(), label: i });
  }
  return data;
};

export const showStatusUser = status => {
  switch (status) {
    case 4:
      return '本合格';
    case 5:
      return '休会';
    default:
      return '--';
  }
};

export const resizeIcon = item => {
  switch (item.title) {
    case '面白い':
      return { width: 43, height: 30 };
    case '明るい':
      return { width: 44, height: 44 };
    case 'ときどき飲む':
      return { width: 40, height: 35 };
    case '飲む':
      return { width: 30, height: '35.09px' };
    case 'ドライブ':
      return { maxWidth: 'unset', width: 61, height: 26 };
    case 'キャンプ':
      return { maxWidth: 'unset', width: 44, height: '31.94px' };
    case '旅行':
      return { width: 36, height: 34 };
    case 'ランニング':
      return { width: 45, height: 35, maxWidth: 'unset' };
    case 'サーフィン':
      return { width: 43, height: 46, maxWidth: 'unset', maxHeight: 'unset' };
    case 'ゴルフ':
      return { width: 39, height: 42, maxHeight: 'unset' };
    case 'ダーツ':
      return { width: 39, height: 40, maxHeight: 'unset' };
    case 'サイクリング':
      return { maxHeight: '50%' };
    case '趣味':
      return { width: 39, height: '30.12px', maxWidth: 'unset' };
    case '年齢':
      return { width: '17.46px', height: 41 };
    case '仕事':
      return {
        width: '17.45px',
        height: 49,
        maxWidth: 'unset',
        maxHeight: 'unset'
      };
    case '仕事好き':
      return {
        width: '17.45px',
        height: 49,
        maxWidth: 'unset',
        maxHeight: 'unset'
      };
    case '写真・カメラ':
      return { width: 44, height: 31, maxWidth: 'unset' };
    case '家で':
      return { width: 39, height: '30.12px' };
    case '優しい':
      return { width: 33, height: 31 };
    case 'グルメ':
      return { width: 19, height: 46, maxHeight: 'unset' };
    case '家庭的':
      return { width: 40, height: 44, maxHeight: 'unset' };
    case '体型':
      return { width: 21, height: 51, maxHeight: 'unset' };
    case 'お酒':
      return { width: 21, height: 42, maxHeight: 'unset' };
    case '身長':
      return { width: 20, height: 49, maxHeight: 'unset' };
    case 'ときどき吸う':
      return { marginBottom: 11 };
    case '吸う':
      return { marginBottom: 11 };
    case 'タバコ':
      return { marginBottom: 13 };
    case '車・ドライブ':
      return { width: 70, height: 36, maxWidth: '100%' };
    default:
      return;
  }
};

export const renderMultiLabel = item => {
  switch (item.title) {
    case '落ち着きが':
      return [item.title, 'ある'];
    case 'ぱっちりした':
      return [item.title, '目'];
    case '結婚に対する':
      return [item.title, '意思'];
    case 'スポーツ':
      return [item.title, '観戦'];
    case 'カフェ':
      return [item.title, 'スイーツ'];
    case '(電子タバコ)':
      return ['吸う', item.title];
    case '相手が嫌':
      return [item.title, 'なら辞める'];
    case '今は考えて':
      return [item.title, 'いない'];
    case 'いい人がいたら':
      return [item.title, 'したい'];
    case 'すぐにでも':
      return [item.title, 'したい'];
    case '(子供なし)':
      return ['あり', item.title];
    case '(子供あり:別居)':
      return ['あり', item.title];
    case '(子供あり:同居)':
      return ['あり', item.title];
    case 'ドライブ':
      return ['車', item.title];
    case 'ダーツ':
      return [item.title, 'ビリヤード'];
    case 'サーフィン':
      return [item.title, 'スノボ'];
    case 'インテリア':
      return [item.title, 'DIY'];
    case 'ゲーム':
      return [item.title, '漫画'];
    case '読書':
      return [item.title, '小説'];
    case '映画':
      return [item.title, 'ドラマ'];
    case '料理':
      return [item.title, 'お菓子作り'];
    case '家で':
      return [item.title, 'まったり'];
    case '旅行':
      return [item.title, '温泉'];
    default:
      return;
  }
};

export const formatMatchProfile = resp => {
  const emptyWeeks = [[], [], []];
  let thisWeek = [];
  let nextWeek = [];
  let nextNextWeek = [];
  let newWeeks = [];
  let response;
  const revertDating = day => {
    if (day.length > 1 && day[0].dating_day_slot === DateSlot.EVENING_TIME) {
      day.reverse();
    }
    return day;
  };
  const filterDatingDay = week =>
    [
      ...datingDays
        .map(day => (week && week[day] ? revertDating(week[day]) : ''))
        .filter(item => item)
    ].filter(item => Object.keys(item).length);

  const weeks = resp.weeks;

  const weekOrders = [
    WEEK_ORDER.THIS_WEEK,
    WEEK_ORDER.NEXT_WEEK,
    WEEK_ORDER.NEXT_NEXT_WEEK
  ];

  const filteredWeeks = weekOrders.map(
    WEEK_ORDER => weeks.find(week => week.week_diff === WEEK_ORDER) || {}
  );

  thisWeek = filterDatingDay(filteredWeeks[0]);
  nextWeek = filterDatingDay(filteredWeeks[1]);
  nextNextWeek = filterDatingDay(filteredWeeks[2]);

  newWeeks.push(thisWeek.flat(), nextWeek.flat(), nextNextWeek.flat());

  response = {
    ...resp,
    weeks: newWeeks.length ? newWeeks : emptyWeeks
  };
  return response;
};

export const generateHeaderTitle = day => {
  switch (day.toLowerCase()) {
    case 'wednesday':
      return '水曜の詳細';
    case 'saturday':
      return '土曜の詳細';
    case 'sunday':
      return '日曜の詳細';
    default:
      return;
  }
};

export const timeNow = (date, hours = 0) => {
  let now = new Date(date),
    d = now.getDate(),
    month = now.getMonth() + 1,
    y = now.getFullYear(),
    h = now.getHours() + hours,
    // h = now.setHours(now.getHours() + hours),
    min = now.getMinutes(),
    s = now.getSeconds();

  if (d < 10) d = '0' + d;
  if (h < 10) h = '0' + h;
  if (month < 10) month = '0' + month;
  if (min < 10) min = '0' + min;
  if (s < 10) s = '0' + s;
  return y + '-' + month + '-' + d + ' ' + h + ':' + min + ':' + s;
  // return type === 1? y + '-' + month + '-' + d: now.toLocaleDateString('en-US',options);
};

export const convertLocalTimeToDate = time => {
  const jsDate = convert(time).toDate(); // Convert to JavaScript `Date` object

  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
  };

  return jsDate.toLocaleDateString('en-US', options); // Saturday, April 28, 2018, 12:34
};

export const renderTitlePicker = label => {
  return label;
};

// eslint-disable-next-line
export const isEmail = email =>
  /^[a-zA-Z0-9]([\.\-\_]?[a-zA-Z0-9])*@[a-zA-Z0-9]([\.-]?[a-zA-Z0-9])*(\.[a-zA-Z0-9]{2,3})+$/.test(
    email
  );

// change the type of the input to password
export const changeToPassword = () => {
  setTimeout(function () {
    document.getElementById('password').setAttribute('type', 'password');
  }, 300);
};

/**
 *
 * @param {String} currentServerTime
 * @param {String} datingDateText
 * @returns
 */
export const checkDatingDayBefore = (currentServerTime, datingDateText) => {
  const currentDate = moment(currentServerTime).format('MM/DD');
  const datingDate = datingDateText
    ? (isString(datingDateText)
        ? datingDateText
        : datingDateText.toString()
      ).substring(0, 5)
    : currentDate;

  // condition:
  // 1. currentMonth is 12 and datingMonth is 1, which means datingDate is in the new year
  // 2. currentDate < datingDate
  return (
    (parseInt(currentDate.split('/')[0]) === 12 &&
      parseInt(datingDate.split('/')[0]) === 1) ||
    moment(currentDate, 'MM/DD').isBefore(moment(datingDate, 'MM/DD'))
  );
};

// set default value for items based on value
export const setDefaultValueForItems = (items, value) => {
  if (findIndex(items, e => e.checked === true) < 0) {
    items[findIndex(items, e => e.value === value)].checked = true;
  }
};

/**
 * Find element's ancester following className
 * @param {HTMLElement} el
 * @param {String} cls
 */
export const findAncestor = (el, cls) => {
  while (el && (el = el.parentElement) && !el.classList.contains(cls)) {
    //
  }
  return el;
};

// generate number array based on input data
export function generateNumberArray(
  begin,
  end,
  title = '歳',
  userAge = null,
  isPremiumUser = false,
  check = false,
  userGender = 0,
  specialTitleBegin = '',
  specialTitleEnd = '',
  premiumText,
  isEvenIDNotTestVersion = false
) {
  let array = [];
  for (let i = begin; i <= end; i++) {
    if (userAge && (!check || (check && userGender === UserGender.MALE))) {
      if (
        userAge >=
          (isEvenIDNotTestVersion
            ? RegistrationFormKey.AGE_NEED_TO_CHECK_REGISTRATION_VERSION
            : RegistrationFormKey.AGE_NEED_TO_CHECK_PREMIUM) &&
        i <=
          parseInt(userAge) -
            RegistrationFormKey.MIN_RANGE_AFTER_40_NEED_PREMIUM &&
        !isPremiumUser
      ) {
        array.push({
          label:
            (i < 10 ? '0' : '') +
            i +
            `歳 ${premiumText || '(プレミアムプラン限定)'}`,
          value: i
        });
      } else {
        array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
      }
    } else {
      if (specialTitleBegin || specialTitleEnd) {
        if (specialTitleBegin && i === begin) {
          array.push({
            label: (i < 10 ? '0' : '') + i + specialTitleBegin,
            value: i
          });
        } else if (specialTitleEnd && i === end) {
          array.push({
            label: (i < 10 ? '0' : '') + i + specialTitleEnd,
            value: i
          });
        } else {
          array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
        }
      } else {
        array.push({ label: (i < 10 ? '0' : '') + i + title, value: i });
      }
    }
  }
  return array;
}

// update 'AgeTo' data array for 2nd step of 1st form
export const updateAgeToData = (ageArray, isPremiumUser, userAge) => {
  if (ageArray[0].length === 0) return [];
  const array = ageArray[0].map(e => ({
    label:
      e.value === 99 ||
      parseInt(e.value) >
        parseInt(userAge) - RegistrationFormKey.MIN_RANGE_AFTER_40_NEED_PREMIUM
        ? e.label
        : isPremiumUser
        ? e.label.substring(0, parseInt(e.label.indexOf('歳') + 1))
        : e.label.substring(0, parseInt(e.label.indexOf('歳') + 1)) +
          '(プレミアムプラン限定)',
    value: e.value
  }));
  return [array];
};

export const handleGoBackPage = container => {
  container.props.history.action === 'POP'
    ? container.props.history.push('/profile')
    : container.props.history.goBack();
};

export const validateUserRegistrationStep = (
  container,
  registrationStep,
  currentStep,
  gender = null
) => {
  //if user has not completed previous step then we navigate user to correct step
  if (registrationStep === null) {
    registrationStep = -1;
  }
  let preStep = currentStep;
  let isMale = gender === UserGender.MALE;
  if (isMale && currentStep === REGISTRATION_STEPS.StepSix) {
    preStep -= 2;
  } else if (isMale && currentStep === REGISTRATION_STEPS.StepFive) {
    preStep += 2;
  } else {
    preStep -= 1;
  }

  if (currentStep === REGISTRATION_STEPS.StepOnePointFive) {
    preStep = REGISTRATION_STEPS.StepOne;
  }
  if (currentStep === REGISTRATION_STEPS.StepSeven) {
    preStep = REGISTRATION_STEPS.StepSixPointFive;
  }

  let compareStep = registrationStep;
  if (isMale && currentStep === REGISTRATION_STEPS.StepFive && registrationStep > REGISTRATION_STEPS.StepFive) {
    compareStep = REGISTRATION_STEPS.StepFour;
  }

  if (compareStep < preStep) {
    const step = registrationStep === null ? 0 : registrationStep;
    if (step < REGISTRATION_STEPS.StepSeven) {
      let stepFirstForm = step + 1;
      if (isMale && stepFirstForm === REGISTRATION_STEPS.StepFive) {
        stepFirstForm += 1;
      }
      container.props.history.push('/registration-form/step-' + stepFirstForm);
      return false;
    } else {
      container.props.history.push('/registration-form');
      return false;
    }
  } else {
    if (
      isNil(gender) && isMale &&
      currentStep === REGISTRATION_STEPS.StepFive
    ) {
      container.props.history.push('/registration-form');
      return false;
    }
  }
  return true;
};

export const showFeeWithComma = fee => {
  return !!fee ? fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : fee;
};

export const bindingHeight = (
  height,
  min_height,
  max_height,
  noSpace = false
) => {
  if (height === min_height) {
    return height + `${noSpace ? '' : ' '}cm以下`;
  } else if (height === max_height) {
    return height + `${noSpace ? '' : ' '}cm以上`;
  } else if (height > 0) {
    return height + `${noSpace ? '' : ' '}cm`;
  } else {
    return '選択してください';
  }
};

export const isBeforeApprovalUser = userStatus => {
  let beforeApprovalUserStatus = [
    UserStatus.INCOMPLETE_PROFILE,
    UserStatus.AWAITING_SCREENING
  ];
  return beforeApprovalUserStatus.includes(userStatus);
};

export const getLabelComboBox = (event, array) => {
  // eslint-disable-next-line
  return array.length > 0 ? array.filter(e => e.value == event.target.value)[0].label : '';
};

export const getUserTypeValue = userType => {
  const lpQueryStr = queryString.parse(
    localStorage.getItem(LpQueryStr.LOCAL_STORAGE_KEY)
  );
  const queryUserType = lpQueryStr ? lpQueryStr.user_type : null;
  return userType || queryUserType;
};

export const isIOSSafari = () => {
  const ua = window.navigator.userAgent;
  const iOSMobile = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  const isSafari =
    ua &&
    ua.indexOf('CriOS') === -1 &&
    ua.indexOf('FxiOS') === -1 &&
    ua.indexOf('OPiOS') === -1;
  return iOSMobile && webkit && isSafari;
};

export const renderLeftContentChat = (
  isArrow,
  isWhiteMode,
  onCLick,
  countUnread,
  isShowUnreadMessage,
  hasWhiteHeader
) => {
  return isArrow ? (
    <>
      <ArrowLeftChat
        onClick={onCLick}
        style={{ stroke: hasWhiteHeader ? 'var(--black)' : 'var(--white)' }}
      />
      <span style={{ marginLeft: '4px', fontSize: '18px' }}>
        {countUnread && isShowUnreadMessage ? countUnread : ''}
      </span>
    </>
  ) : (
    <Menu style={{ stroke: 'var(--Main-Color)' }} />
  );
};

export const renderStyleIcon = (
  checked,
  isStroke = true,
  marginRight = false,
  activeClass = null
) => {
  const isWhiteMode = JSON.parse(localStorage.getItem('isWhiteMode'));

  const defaultStyleStroke = isWhiteMode
    ? styleSvg.isSVGStrokeBlack
    : styleSvg.isSVGStrokeWhite;

  const defaultStyleBoth = isWhiteMode
    ? styleSvg.isSVGBothBlack
    : styleSvg.isSVGBothWhite;

  return classNames(
    isStroke ? defaultStyleStroke : defaultStyleBoth,

    isStroke
      ? activeClass
        ? { [activeClass]: checked }
        : {
            [styleSvg.isSVGStrokeWhite]: checked,
            [styleSvg.marginRight]: marginRight
          }
      : { [styleSvg.isSVGBothWhite]: checked }
  );
};

export const convertDateSlotTime = slot => {
    switch (slot) {
    case DateSlot.LUNCH_TIME:
      return '11時ごろ';
    case DateSlot.AFTER_LUNCH_TIME:
      return '14時ごろ';
    case DateSlot.EVENING_TIME:
      return '16時半ごろ';
    case DateSlot.AFTER_EVENING_TIME:
      return '19時ごろ';
    default:
      return '';
  }
};

export const renderStarValue = totalPoint => {
  switch (true) {
    //Total Point  Good Factor
    case totalPoint >= ratingPoint.STAR_5:
      return '5';
    case totalPoint >= ratingPoint.STAR_4_5:
      return '4_5';
    case totalPoint >= ratingPoint.STAR_4:
      return '4';
    case totalPoint >= ratingPoint.STAR_3_5:
      return '3_5';
    case totalPoint >= ratingPoint.STAR_3:
      return '3';
    //Total Point Compaint
    case totalPoint <= ratingPoint.STAR_1:
      return '1';
    case totalPoint <= ratingPoint.STAR_1_5:
      return '1_5';
    case totalPoint <= ratingPoint.STAR_2:
      return '2';
    case totalPoint <= ratingPoint.STAR_2_5:
      return '2_5';
    default:
      return null;
  }
};

export const getStarPoint = totalPoint => {
  switch (true) {
    //Total Point  Good Factor
    case totalPoint >= ratingPoint.STAR_5:
      return 5;
    case totalPoint >= ratingPoint.STAR_4_5:
      return 4.5;
    case totalPoint >= ratingPoint.STAR_4:
      return 4;
    case totalPoint >= ratingPoint.STAR_3_5:
      return 3.5;
    case totalPoint >= ratingPoint.STAR_3:
      return 3;
    //Total Point Compaint
    case totalPoint <= ratingPoint.STAR_1:
      return 1;
    case totalPoint <= ratingPoint.STAR_1_5:
      return 1.5;
    case totalPoint <= ratingPoint.STAR_2:
      return 2;
    case totalPoint <= ratingPoint.STAR_2_5:
      return 2.5;
    default:
      return null;
  }
};

export const bingoBannerCondition = (userGender, currentServerTime) => {
  return (
    userGender === UserGender.MALE &&
    moment(currentServerTime).isBefore('2022-07-02')
  );
};

export const handleRenderTextJapan = (key, preFix, renderText) => {
  const t = i18n.getFixedT(null, null, preFix);
  return i18n.exists(key) ? t(key) : renderText[key];
};

export const userHasCertificate = userCertificate => {
  return userCertificate.filter(
    item =>
      item.type === USER_CERTIFICATE_TYPE.ANNUAL_INCOME &&
      item.status === USER_CERTIFICATE_STATUS.APPROVED
  ).length;
};

export const renderDayTitle = txtTitle => {
  const t = i18n.getFixedT(null, null, 'common');
  let newTitle = txtTitle;
  datingDays.forEach(item => {
    const day = item.substring(0, 3);
    if (newTitle.includes(day)) {
      newTitle = newTitle.replace(day, t(`common:${day.toLowerCase()}`));
    }
  });
  return newTitle;
};

export const zendeskHelperMale =
  'https://bachelorapp.zendesk.com/hc/ja/categories/4408155186841';
export const zendeskHelperFemale =
  'https://bachelorapp.zendesk.com/hc/ja/categories/4408155187865';

export const secondsToTime = secs => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  let obj = {
    h: hours < 10 ? `0${hours}` : hours,
    m: minutes < 10 ? `0${minutes}` : minutes,
    s: seconds < 10 ? `0${seconds}` : seconds
  };
  return obj;
};

export function formatMoney(money, locale = 'ja-JP') {
  return money.toLocaleString(locale);
}

export const textLengthFullWidth = str => {
  const l = str.length;
  let charCode = 0;
  let length = 0;
  for (let i = 0; i < l; i++) {
    charCode = str.charCodeAt(i);
    if (0x0000 <= charCode && charCode <= 0x0019) {
      length += 0;
    } else if (
      (0x0020 <= charCode && charCode <= 0x1fff) ||
      (0xff61 <= charCode && charCode <= 0xff9f)
    ) {
      length += 1;
    } else if (
      (0x2000 <= charCode && charCode <= 0xff60) ||
      0xffa0 <= charCode
    ) {
      length += 2;
    }
  }
  return length;
};

export const showValidateTextError = validatingString => {
  if (textLengthFullWidth(validatingString) > validatingString.length)
    return validatingString.length >= MAX_LENGTH_TEXT_INPUT_FULL_WIDTH;
  return textLengthFullWidth(validatingString) >= DEFAULT_MAX_LENGTH_TEXT_INPUT;
};

export const userHasOddId = userId => {
  return userId % 2 === 1;
};

export const userHasEvenId = userId => {
  return userId % 2 === 0;
};

export const isIphone = /iPhone/i.test(window.navigator.userAgent);

export const isUserHasBlackPlan = userPlan => {
  return userPlan === CostPlan.BLACK;
};

export const isMale = userGender => {
  return userGender === UserGender.MALE;
};

export const isFemale = userGender => {
  return userGender === UserGender.FEMALE;
};

export const copyToClipboard = async text => {
  await window.navigator.clipboard.writeText(text);
};

export const checkActiveSubscriptionPause = response => {
  return (
    response.status === ResponseStatus.VALIDATEFAIL &&
    response.data &&
    response.data.errors &&
    (response.data.errors.type ===
      SUBSCRIPTION_PAUSE_ERROR_TYPE.ACTIVE_SUBSCRIPTION_PAUSE ||
      response.data.errors.type ===
        SUBSCRIPTION_PAUSE_ERROR_TYPE.EXPIRED_SUBSCRIPTION_PAUSE_CANCEL_PERIOD)
  );
};

export const triggerGoogleTagManager = (isTrigger, eventName) => {
  try {
    if (isTrigger) {
      const tagManagerArgs = {
        dataLayer: {
          event: eventName
        }
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  } catch (error) {
    const errorData = {
      message:
        `Error tag for marketing ${eventName}` +
        error.message +
        ` at ${window.location.pathname}`,
      stack: error.stack
    };
    store.dispatch(requestSendExceptionNotice({ error: errorData }));
    return;
  }
};

export const datingItemIcon = className => ({
  age: <Age stroke="#DE9C4E" fill="#DE9C4E" className={className} />,
  body_type: <BodyType stroke="#DE9C4E" fill="#DE9C4E" className={className} />,
  face_preferences: (
    <Eye stroke="#DE9C4E" fill="#DE9C4E" className={className} />
  ),
  dating_area: <Map stroke="#DE9C4E" fill="#DE9C4E" className={className} />,
  dating_time: <Clock stroke="#DE9C4E" fill="#DE9C4E" className={className} />
});

export const isSaveSchoolData = (oldSChoolValue, currentSchoolValue) => {
  return (
    ((oldSChoolValue === currentSchoolValue || currentSchoolValue === 0) &&
      oldSChoolValue !== 0) ||
    (oldSChoolValue === 0 && currentSchoolValue === 0)
  );
};

export const compareSubmitDataParticipationItem = (a, b) => {
  if (a.type < b.type) {
    return -1;
  }
  if (a.type > b.type) {
    return 1;
  }
  return 0;
};

export const getRematchDeadLineTime = (slot) => {
  switch (slot) {
    case DateSlot.LUNCH_TIME:
      return 8;
    case DateSlot.AFTER_LUNCH_TIME:
      return 10;
    case DateSlot.EVENING_TIME:
      return 13;
    case DateSlot.AFTER_EVENING_TIME:
      return 15;
    default:
      return 8;
  }
}

export const getNotificationRematchTime = (slot) => {
  switch (slot) {
    case DateSlot.LUNCH_TIME:
      return '8時30分ごろ';
    case DateSlot.AFTER_LUNCH_TIME:
      return '10時30分ごろ';
    case DateSlot.EVENING_TIME:
      return '13時30分ごろ';
    case DateSlot.AFTER_EVENING_TIME:
      return '15時30分ごろ';
    default:
      return '8時30分ごろ';
  }
}

export const convertDateSlot = (slot) => {
  switch (slot) {
    case DateSlot.LUNCH_TIME:
      return 'lunch';
    case DateSlot.AFTER_LUNCH_TIME:
      return 'early-afternoon';
    case DateSlot.EVENING_TIME:
      return 'late-afternoon';
    case DateSlot.AFTER_EVENING_TIME:
      return 'evening';
    default:
      return 7;
  }
}


export const isEmptyObject = value => {
  return typeof value === 'object' && Object.keys(value).length === 0;
};
